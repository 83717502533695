import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { SEO } from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <main className="container mx-auto flex min-h-[870px] w-full  rounded-xl bg-transparent md:my-20 md:min-h-[850px] md:max-w-[649px] md:flex-row md:pb-0 2xl:my-40">
      <div className="w-full text-center">
        <h1 className="mt-5 text-center text-4xl font-bold text-black md:mt-5">
          Ooops.
        </h1>
        <p className="mt-2 text-xl capitalize text-sub-headline">
          The Page You Were Looking For Couldn't Be Found
        </p>
        <Link
          className="mt-5 inline-block rounded-full border-2 border-none bg-button py-2 px-10 text-lg text-white transition duration-1000 ease-out hover:bg-indigo-500 hover:ease-in"
          to="/"
        >
          Back To Home
        </Link>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage

export const Head = () => (
  <SEO
    title="Not Found"
    description={undefined}
    pathname="404"
    children={undefined}
  ></SEO>
)
