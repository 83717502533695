import React from "react"

const Footer = () => (
  <footer
    className="flex w-full flex-col bg-primary py-5 px-10 text-sm md:fixed md:bottom-0 md:flex-row md:py-2"
    style={{ borderTop: " 2px solid #e2e2e2" }}
  >
    <div className="w-full text-center md:w-1/2 md:text-left">
      <a
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-700 hover:text-black hover:overline"
        href="https://www.facebook.com/furkanulisl/"
        target="_blank"
      >
        Facebook
      </a>

      <a
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-700 hover:text-black hover:overline"
        href="https://se.linkedin.com/in/mdfurkanislam"
        target="_blank"
      >
        Linkedin
      </a>
      <a
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-700 hover:text-black hover:overline"
        href="https://github.com/mdfurkanul/"
        target="_blank"
      >
        Github
      </a>
      <a
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-700 hover:text-black hover:overline"
        href="https://stackoverflow.com/users/4290141/md-furkanul-islam"
        target="_blank"
      >
        Stackoverflow
      </a>
    </div>
    <div className="mt-5 w-full text-center text-link md:mt-0 md:w-1/2 md:text-right">
      © Copyright {new Date().getFullYear()}. All rights reserved
    </div>
  </footer>
)

export default Footer
