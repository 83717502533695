import { Link } from "gatsby"
import React from "react"

function Menu() {
  return (
    <div className="menu">
      <Link
        to="../"
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-1000 hover:underline"
        activeStyle={{ color: "black" }}
      >
        About Me
      </Link>
      <Link
        to="/services"
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-1000 hover:underline"
        activeStyle={{ color: "black" }}
      >
        Services
      </Link>
      <a
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-1000 hover:underline"
        href="https://iubatians.blogspot.com"
        target="_blank"
      >
        Blog
      </a>
      <Link
        to="/contact"
        className="mr-6 text-link decoration-black decoration-2 underline-offset-8 transition duration-1000 hover:underline"
        activeStyle={{ color: "black" }}
      >
        Contact
      </Link>
    </div>
  )
}

export default Menu
