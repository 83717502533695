import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
export const SEO = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
  }

  return (
    <>
      <title>{seo.title} | MD Furkanul Islam</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="author" content="MD Furkanul Islam" />
      <meta
        name="keywords"
        content="Funnel fix, Custom script, Creating a zap, Custom domain setup, Any integrations, Shopify add to cart, Optin funnel, High ticket funnl, Product launch funnel, Sales funnel,  Membership site, Webinar  setup ,cf hacker, cf pro, cf tools, cf pro tools, cfhackers, funnel hackers, cf ninja, clickfunnels ninja, clickf funnel, ninja, funnel, click, leads, sales, graphics, freelancer, consultant"
      />
      <meta property="og:image" content={seo.image} />
      {children}
    </>
  )
}
