import React from "react"
import { Link } from "gatsby"
import Menu from "./Menu"

const Header = () => {
  return (
    <header className="flex flex-row bg-primary py-5 px-2 text-lg md:px-10">
      <Link to="../" className="w-3/4 font-semibold md:w-1/2">
        <span className="rounded-md border bg-black px-2 py-1 text-white">
          MD
        </span>{" "}
        Furkanul Islam
      </Link>
      <div className="w-1/4 items-center justify-end text-right md:w-1/2">
        <div id="hamburger" className=" float-right">
          <input className="md:hidden" type="checkbox" />
          <span className="md:hidden"></span>
          <span className="md:hidden"></span>
          <span className="md:hidden"></span>

          <Menu />
        </div>
      </div>
    </header>
  )
}

export default Header
